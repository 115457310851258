export const reviewData = [
    {
      rate: 5,
      message:
        "I have a pet plan with Premier and their customer service is second to none. I've been speaking to Tiffany regularly for over a year and she's always engaging, polite, professional and always does her best to help me. Previously, I had a plan with someone else and they were obnoxious, rude and blamed me for their errors! Tiffany is a breath of fresh air compared to what I've experienced before. She is a credit to Premier. Thank you!",
      user: {
        name: "Antony P",
        username: "Antony P",
        image:
          "https://lh3.googleusercontent.com/a/ACg8ocJk1addyJkZ7ujgAIcxi0PbyB6dvvT_wZvOLnNZAhY888uP1g=s40-c-rp-mo-br100",
      },
    },
    {
      rate: 5,
      message:
        "Love this vet & the employees! They’re super flexible and always willing to assist. Definitely a family oriented environment & outside of my pups I always look forward to appointments! Kudos to Tiffany specifically for her efficiency and quick response.",
      user: {
        name: "byrd layd",
        username: "byrd layd",
        image:
          "https://lh3.googleusercontent.com/a-/ALV-UjW1UsTohXAva1JNDyEJlKgUGej7oBnQ3AmbTtXWBS1P0BdDTw=s40-c-rp-mo-br100",
      },
    },
    {
      rate: 5,
      message:
        "Premier Vet Alliance has been great for our clinic. Clients love that they can get the ultimate care for there pet without breaking the bank. Tiffany and Alyson have been a tremendous help with navigating updates whether it be for the clinic or clients concerns. 10/10 would highly recommend!",
      user: {
        name: "Pets Account",
        username: "Pets Account",
        image:
          "https://lh3.googleusercontent.com/a/ACg8ocILC6Wnn3vAcJnaOj2FUMlO0Cbjg4KfFxL_yHpROq6k0XsQBQ=s40-c-rp-mo-br100",
      },
    },
    {
      rate: 5,
      message:
        "I highly recommend Premiere Vet for your pets. I reside in the United States and they provide excellent care for my pets. They receive their checkups, well and sick visits, their vaccination shots and flea, tick, heart worm meds. My customer service Rep Tiffany is amazing!  She’s always helpful, very pleasant, courteous and very knowledgeable! It’s a pleasure to contact Premiere Vet, as I know Tiffany will be on the other of the call to help with any questions I may have.",
      user: {
        name: "Annette Rorke",
        username: "Annette Rorke",
        image:
          "https://lh3.googleusercontent.com/a/ACg8ocLx1o4SSMBUdAHhiohAfYv-SuPxduB3wSsqnQlnZTjKvvY-hg=s40-c-rp-mo-br100",
      },
    },
    {
      rate: 5,
      message:
        "I have put my trust in Premier Vet Alliance for years! They have always been on top of it. Tiffany has always been my go to - she is able to handle any situation in a calm & professional manner. We will continue to use this service for the years to come!",
      user: {
        name: "Morgan Taylor",
        username: "Morgan Taylor",
        image:
          "https://lh3.googleusercontent.com/a/ACg8ocKABJoP64cjb3E9ebn8oovbgnNgsjisZnUq6q9R6xaAEmdudQ=s40-c-rp-mo-br100",
      },
    },
    {
      rate: 4,
      message: "",
      user: {
        name: "Rodney Torres",
        username: "Rodney Torres",
        image:
          "https://lh3.googleusercontent.com/a/ACg8ocKAGU4luiEaPHQscyHNHtQQwKCwwIUkYoACjTLmN01c9SyMow=s40-c-rp-mo-br100",
      },
    },
  ];